import {
  AddressLine1Input,
  AddressStateInput,
  DateInput,
  DiagnosisInput,
  EmailAddressInput,
  GenderInput,
  Input,
  NumberInput,
  ProductSubcategoryInput,
} from '../../../../components/FormInputs';
import { AppOrderSeedStatusChoices } from '../../../../api-clients/falcon-api/graphql/types.generated';
import {
  assignOrderSeedDetails,
  getOrderSeedFromResponse,
  SupplierIntakeFaxOrderSeed,
} from '../SupplierFaxOrderWizard.utils';
import { ColorsV2 } from '@tomorrow/ui/theme';
import { ConfidenceIcon, InputContainer, InputSection, InputSubSection } from '../components/InputSection';
import {
  DocumentFieldId,
  getOrderFieldRequirements,
  isOrderSeedCorrected,
  OrderDocumentExtractionFormValues,
  toCorrectedExtractionResultsMutationInput,
  toDefaultFormValues,
} from './DocumentStep.utils';
import { HighlightFieldCb, OrderWizardStepComponentProps, StepRef } from '../../IntakeFaxOrderWizard';
import { PatientDateOfBirthInput } from '../../../../components/PatientModal/PatientInputs';
import { RiAddLine, RiDeleteBinLine } from '@remixicon/react';
import { useAutoAnimate } from '@formkit/auto-animate/react';
import { useFeatureFlags } from '../../../../hooks/useFeatureFlags';
import { useFieldArray, useForm, UseFormReturn } from 'react-hook-form';
import { useIntakeSubcategories } from '../../../../api-clients/falcon-api/hooks/useIntakeSubcategories';
import { useUpdateOrderSeedMutation } from '../../../../api-clients/falcon-api/graphql/mutations/updateOrderSeed.generated';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import React, { forwardRef, memo, useCallback, useImperativeHandle, useMemo } from 'react';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

export type OrderDocumentExtractionForm = UseFormReturn<OrderDocumentExtractionFormValues>;

export const QualificationsStep = memo(
  forwardRef<StepRef, OrderWizardStepComponentProps<SupplierIntakeFaxOrderSeed, DocumentFieldId>>(
    function QualificationsStep({ orderSeed, onOrderProduce, onNextStep, onHighlightField }, ref) {
      const flags = useFeatureFlags();
      const updateOrderSeedMutation = useUpdateOrderSeedMutation();
      const isCorrected = isOrderSeedCorrected(orderSeed);

      const { requirementErrors, requirementFieldError, requirementFieldDictionary } = useMemo(
        () => getOrderFieldRequirements(orderSeed.extractionResultsSet?.orderFieldRequirements, flags),
        [flags, orderSeed],
      );

      const form = useForm<OrderDocumentExtractionFormValues>({
        defaultValues: toDefaultFormValues(orderSeed),
        errors: requirementErrors,
      });

      const saveOrder = useCallback(
        async function saveOrder(
          values: OrderDocumentExtractionFormValues,
          onSuccess: (orderSeed: SupplierIntakeFaxOrderSeed) => void,
        ) {
          if (!orderSeed.id) throw new Error('Missing order seed id');

          const response = await updateOrderSeedMutation.mutateAsync({
            input: {
              orderSeedId: orderSeed.id,
              status: AppOrderSeedStatusChoices.Reviewed,
              extractionResultsSetCorrections: toCorrectedExtractionResultsMutationInput(values),
            },
          });

          const responseOrderSeed = getOrderSeedFromResponse(response);
          const updatedOrderSeed = onOrderProduce((draft) => assignOrderSeedDetails(draft, responseOrderSeed));

          onSuccess(updatedOrderSeed);
        },
        [onOrderProduce, orderSeed, updateOrderSeedMutation],
      );

      const handleFormSubmit = useCallback(async () => {
        // Clear any existing errors before submitting
        form.clearErrors();

        await form.handleSubmit(async (values) => {
          await saveOrder(values, onNextStep);
        })();
      }, [form, onNextStep, saveOrder]);

      useImperativeHandle(
        ref,
        () => ({
          triggerFormSubmit: handleFormSubmit,
        }),
        [handleFormSubmit],
      );

      const handleOnHighlightField: HighlightFieldCb<DocumentFieldId> = (
        inputContainerElement,
        highlightId,
        highlightEnabled,
      ) => {
        if (!flags.fax_intake_flow_highlights) return;

        onHighlightField(inputContainerElement, highlightId, highlightEnabled);
      };

      return (
        <Box flexGrow="1">
          <Box component="form" display="flex" height="100%" onSubmit={handleFormSubmit}>
            <Stack bgcolor={ColorsV2.white} flexGrow="1" padding="20px 40px" spacing={2}>
              <FormProgressHeader
                faxBackError={requirementFieldError}
                numOfFields={Object.keys(requirementFieldDictionary).length}
              />
              <InputSection label="Patient">
                <InputContainer
                  hidden={requirementFieldDictionary.patient_first_name === undefined}
                  highlightFieldId="patient_first_name"
                  icon={
                    <ConfidenceIcon
                      confidence={orderSeed.extractionResultsSet?.patientFirstNameConfidence}
                      control={form.control}
                      corrected={isCorrected}
                      name="patient.firstName"
                    />
                  }
                  onHighlightField={handleOnHighlightField}
                >
                  <Input control={form.control} name="patient.firstName" />
                </InputContainer>
                <InputContainer
                  hidden={requirementFieldDictionary.patient_middle_name === undefined}
                  highlightFieldId="patient_middle_name"
                  icon={
                    <ConfidenceIcon
                      confidence={orderSeed.extractionResultsSet?.patientMiddleNameConfidence}
                      control={form.control}
                      corrected={isCorrected}
                      name="patient.middleName"
                    />
                  }
                  onHighlightField={handleOnHighlightField}
                >
                  <Input control={form.control} name="patient.middleName" />
                </InputContainer>
                <InputContainer
                  hidden={requirementFieldDictionary.patient_last_name === undefined}
                  highlightFieldId="patient_last_name"
                  icon={
                    <ConfidenceIcon
                      confidence={orderSeed.extractionResultsSet?.patientLastNameConfidence}
                      control={form.control}
                      corrected={isCorrected}
                      name="patient.lastName"
                    />
                  }
                  onHighlightField={handleOnHighlightField}
                >
                  <Input control={form.control} name="patient.lastName" />
                </InputContainer>
                <InputContainer
                  hidden={requirementFieldDictionary.patient_date_of_birth === undefined}
                  highlightFieldId="patient_date_of_birth"
                  icon={
                    <ConfidenceIcon
                      confidence={orderSeed.extractionResultsSet?.patientDateOfBirthConfidence}
                      control={form.control}
                      corrected={isCorrected}
                      name="patient.dateOfBirth"
                    />
                  }
                  onHighlightField={handleOnHighlightField}
                >
                  <PatientDateOfBirthInput control={form.control} name="patient.dateOfBirth" />
                </InputContainer>
                <InputContainer
                  hidden={requirementFieldDictionary.email === undefined}
                  highlightFieldId="email"
                  icon={
                    <ConfidenceIcon
                      confidence={orderSeed.extractionResultsSet?.patientEmailAddressConfidence}
                      control={form.control}
                      corrected={isCorrected}
                      name="patient.emailAddress"
                    />
                  }
                  onHighlightField={handleOnHighlightField}
                >
                  <EmailAddressInput control={form.control} name="patient.emailAddress" />
                </InputContainer>
                <InputContainer
                  hidden={requirementFieldDictionary.sex === undefined}
                  highlightFieldId="sex"
                  icon={
                    <ConfidenceIcon
                      confidence={orderSeed.extractionResultsSet?.patientSexConfidence}
                      control={form.control}
                      corrected={isCorrected}
                      name="patient.sex"
                    />
                  }
                  onHighlightField={handleOnHighlightField}
                >
                  <GenderInput control={form.control} name="patient.sex" />
                </InputContainer>
                <InputContainer
                  hidden={requirementFieldDictionary.home_phone === undefined}
                  highlightFieldId="home_phone"
                  icon={
                    <ConfidenceIcon
                      confidence={orderSeed.extractionResultsSet?.patientHomePhoneConfidence}
                      control={form.control}
                      corrected={isCorrected}
                      name="patient.homePhoneNumber"
                    />
                  }
                  onHighlightField={handleOnHighlightField}
                >
                  <Input control={form.control} name="patient.homePhoneNumber" />
                </InputContainer>
                <InputContainer
                  hidden={requirementFieldDictionary.weight_in_pounds === undefined}
                  highlightFieldId="weight_in_pounds"
                  icon={
                    <ConfidenceIcon
                      confidence={orderSeed.extractionResultsSet?.patientWeightInPoundsConfidence}
                      control={form.control}
                      corrected={isCorrected}
                      name="patient.weight"
                    />
                  }
                  onHighlightField={handleOnHighlightField}
                >
                  <Input control={form.control} name="patient.weight" />
                </InputContainer>
                <InputContainer
                  hidden={requirementFieldDictionary.height_in_inches === undefined}
                  highlightFieldId="height_in_inches"
                  icon={
                    <ConfidenceIcon
                      confidence={orderSeed.extractionResultsSet?.patientHeightInInchesConfidence}
                      control={form.control}
                      corrected={isCorrected}
                      name="patient.height"
                    />
                  }
                  onHighlightField={handleOnHighlightField}
                >
                  <Input control={form.control} name="patient.height" />
                </InputContainer>
              </InputSection>

              <InputSection label="Addresses">
                <Stack spacing={2}>
                  <InputSubSection label="Shipping">
                    <InputContainer
                      hidden={requirementFieldDictionary.shipping_address_1 === undefined}
                      highlightFieldId="shipping_address_1"
                      icon={
                        <ConfidenceIcon
                          confidence={orderSeed.extractionResultsSet?.shippingAddress.address1Confidence}
                          control={form.control}
                          corrected={isCorrected}
                          name="shippingAddress.addressLine1"
                        />
                      }
                      onHighlightField={handleOnHighlightField}
                    >
                      <AddressLine1Input
                        control={form.control}
                        label="Street Address"
                        name="shippingAddress.addressLine1"
                        onAddressChange={(address) =>
                          form.setValue(
                            'shippingAddress',
                            {
                              addressLine1: address?.streetLine ?? '',
                              addressLine2: '',
                              city: address?.city ?? '',
                              state: address?.state ?? '',
                              postalCode: address?.zipcode ?? '',
                            },
                            { shouldDirty: true },
                          )
                        }
                        transformInitialAddressValue={(address) => ({
                          streetLine: address.addressLine1,
                          secondary: address.addressLine2,
                          city: address.city,
                          state: address.state,
                          zipcode: address.postalCode,
                        })}
                        watchFieldName="shippingAddress"
                      />
                    </InputContainer>
                    <InputContainer
                      hidden={requirementFieldDictionary.shipping_address_2 === undefined}
                      highlightFieldId="shipping_address_2"
                      icon={
                        <ConfidenceIcon
                          confidence={orderSeed.extractionResultsSet?.shippingAddress.address2Confidence}
                          control={form.control}
                          corrected={isCorrected}
                          name="shippingAddress.addressLine2"
                        />
                      }
                      onHighlightField={handleOnHighlightField}
                    >
                      <Input control={form.control} name="shippingAddress.addressLine2" />
                    </InputContainer>
                    <InputContainer
                      hidden={requirementFieldDictionary.shipping_city === undefined}
                      highlightFieldId="shipping_city"
                      icon={
                        <ConfidenceIcon
                          confidence={orderSeed.extractionResultsSet?.shippingAddress.cityConfidence}
                          control={form.control}
                          corrected={isCorrected}
                          name="shippingAddress.city"
                        />
                      }
                      onHighlightField={handleOnHighlightField}
                    >
                      <Input control={form.control} name="shippingAddress.city" />
                    </InputContainer>
                    <InputContainer
                      hidden={requirementFieldDictionary.shipping_state === undefined}
                      highlightFieldId="shipping_state"
                      icon={
                        <ConfidenceIcon
                          confidence={orderSeed.extractionResultsSet?.shippingAddress.stateConfidence}
                          control={form.control}
                          corrected={isCorrected}
                          name="shippingAddress.state"
                        />
                      }
                      onHighlightField={handleOnHighlightField}
                    >
                      <AddressStateInput control={form.control} name="shippingAddress.state" />
                    </InputContainer>
                    <InputContainer
                      hidden={requirementFieldDictionary.shipping_postal_code === undefined}
                      highlightFieldId="shipping_postal_code"
                      icon={
                        <ConfidenceIcon
                          confidence={orderSeed.extractionResultsSet?.shippingAddress.postalCodeConfidence}
                          control={form.control}
                          corrected={isCorrected}
                          name="shippingAddress.postalCode"
                        />
                      }
                      onHighlightField={handleOnHighlightField}
                    >
                      <Input control={form.control} name="shippingAddress.postalCode" />
                    </InputContainer>
                  </InputSubSection>
                  <InputSubSection label="Home">
                    <InputContainer
                      hidden={requirementFieldDictionary.home_address_1 === undefined}
                      highlightFieldId="home_address_1"
                      icon={
                        <ConfidenceIcon
                          confidence={orderSeed.extractionResultsSet?.homeAddress.address1Confidence}
                          control={form.control}
                          corrected={isCorrected}
                          name="homeAddress.addressLine1"
                        />
                      }
                      onHighlightField={handleOnHighlightField}
                    >
                      <AddressLine1Input
                        control={form.control}
                        label="Street Address"
                        name="homeAddress.addressLine1"
                        onAddressChange={(address) =>
                          form.setValue(
                            'homeAddress',
                            {
                              addressLine1: address?.streetLine ?? '',
                              addressLine2: '',
                              city: address?.city ?? '',
                              state: address?.state ?? '',
                              postalCode: address?.zipcode ?? '',
                            },
                            { shouldDirty: true },
                          )
                        }
                        transformInitialAddressValue={(address) => ({
                          streetLine: address.addressLine1,
                          secondary: address.addressLine2,
                          city: address.city,
                          state: address.state,
                          zipcode: address.postalCode,
                        })}
                        watchFieldName="homeAddress"
                      />
                    </InputContainer>
                    <InputContainer
                      hidden={requirementFieldDictionary.home_address_2 === undefined}
                      highlightFieldId="home_address_2"
                      icon={
                        <ConfidenceIcon
                          confidence={orderSeed.extractionResultsSet?.homeAddress.address2Confidence}
                          control={form.control}
                          corrected={isCorrected}
                          name="homeAddress.addressLine2"
                        />
                      }
                      onHighlightField={handleOnHighlightField}
                    >
                      <Input control={form.control} name="homeAddress.addressLine2" />
                    </InputContainer>
                    <InputContainer
                      hidden={requirementFieldDictionary.home_city === undefined}
                      highlightFieldId="home_city"
                      icon={
                        <ConfidenceIcon
                          confidence={orderSeed.extractionResultsSet?.homeAddress.cityConfidence}
                          control={form.control}
                          corrected={isCorrected}
                          name="homeAddress.city"
                        />
                      }
                      onHighlightField={handleOnHighlightField}
                    >
                      <Input control={form.control} name="homeAddress.city" />
                    </InputContainer>
                    <InputContainer
                      hidden={requirementFieldDictionary.home_state === undefined}
                      highlightFieldId="home_state"
                      icon={
                        <ConfidenceIcon
                          confidence={orderSeed.extractionResultsSet?.homeAddress.stateConfidence}
                          control={form.control}
                          corrected={isCorrected}
                          name="homeAddress.state"
                        />
                      }
                      onHighlightField={handleOnHighlightField}
                    >
                      <AddressStateInput control={form.control} name="homeAddress.state" />
                    </InputContainer>
                    <InputContainer
                      hidden={requirementFieldDictionary.home_postal_code === undefined}
                      highlightFieldId="home_postal_code"
                      icon={
                        <ConfidenceIcon
                          confidence={orderSeed.extractionResultsSet?.homeAddress.postalCodeConfidence}
                          control={form.control}
                          corrected={isCorrected}
                          name="homeAddress.postalCode"
                        />
                      }
                      label="Zip Code"
                      onHighlightField={handleOnHighlightField}
                    >
                      <Input control={form.control} name="homeAddress.postalCode" />
                    </InputContainer>
                  </InputSubSection>
                </Stack>
              </InputSection>

              <InputSection label="Insurance">
                <Stack spacing={2}>
                  <InputSubSection label="Primary">
                    <InputContainer
                      hidden={requirementFieldDictionary.primary_insurer_name === undefined}
                      highlightFieldId="primary_insurer_name"
                      icon={
                        <ConfidenceIcon
                          confidence={orderSeed.extractionResultsSet?.primaryInsurance.insurerNameConfidence}
                          control={form.control}
                          corrected={isCorrected}
                          name="insurance.primary.insurerName"
                        />
                      }
                      onHighlightField={handleOnHighlightField}
                    >
                      <Input control={form.control} name="insurance.primary.insurerName" />
                    </InputContainer>
                    <InputContainer
                      hidden={requirementFieldDictionary.primary_plan_name === undefined}
                      highlightFieldId="primary_plan_name"
                      icon={
                        <ConfidenceIcon
                          confidence={orderSeed.extractionResultsSet?.primaryInsurance.planNameConfidence}
                          control={form.control}
                          corrected={isCorrected}
                          name="insurance.primary.plan"
                        />
                      }
                      label="Insurer Plan"
                      onHighlightField={handleOnHighlightField}
                    >
                      <Input control={form.control} name="insurance.primary.plan" />
                    </InputContainer>
                    <InputContainer
                      hidden={requirementFieldDictionary.primary_group_number === undefined}
                      highlightFieldId="primary_group_number"
                      icon={
                        <ConfidenceIcon
                          confidence={orderSeed.extractionResultsSet?.primaryInsurance.groupNumberConfidence}
                          control={form.control}
                          corrected={isCorrected}
                          name="insurance.primary.groupNumber"
                        />
                      }
                      onHighlightField={handleOnHighlightField}
                    >
                      <Input control={form.control} name="insurance.primary.groupNumber" />
                    </InputContainer>
                    <InputContainer
                      hidden={requirementFieldDictionary.primary_policy_number === undefined}
                      highlightFieldId="primary_policy_number"
                      icon={
                        <ConfidenceIcon
                          confidence={orderSeed.extractionResultsSet?.primaryInsurance.policyNumberConfidence}
                          control={form.control}
                          corrected={isCorrected}
                          name="insurance.primary.policyNumber"
                        />
                      }
                      label="Policy Number"
                      onHighlightField={handleOnHighlightField}
                    >
                      <Input control={form.control} name="insurance.primary.policyNumber" />
                    </InputContainer>
                  </InputSubSection>
                  <InputSubSection label="Secondary">
                    <InputContainer
                      hidden={requirementFieldDictionary.secondary_insurer_name === undefined}
                      highlightFieldId="secondary_insurer_name"
                      icon={
                        <ConfidenceIcon
                          confidence={orderSeed.extractionResultsSet?.secondaryInsurance.insurerNameConfidence}
                          control={form.control}
                          corrected={isCorrected}
                          name="insurance.secondary.insurerName"
                        />
                      }
                      onHighlightField={handleOnHighlightField}
                    >
                      <Input control={form.control} name="insurance.secondary.insurerName" />
                    </InputContainer>
                    <InputContainer
                      hidden={requirementFieldDictionary.secondary_plan_name === undefined}
                      highlightFieldId="secondary_plan_name"
                      icon={
                        <ConfidenceIcon
                          confidence={orderSeed.extractionResultsSet?.secondaryInsurance.planNameConfidence}
                          control={form.control}
                          corrected={isCorrected}
                          name="insurance.secondary.plan"
                        />
                      }
                      onHighlightField={handleOnHighlightField}
                    >
                      <Input control={form.control} name="insurance.secondary.plan" />
                    </InputContainer>
                    <InputContainer
                      hidden={requirementFieldDictionary.secondary_group_number === undefined}
                      highlightFieldId="secondary_group_number"
                      icon={
                        <ConfidenceIcon
                          confidence={orderSeed.extractionResultsSet?.secondaryInsurance.groupNumberConfidence}
                          control={form.control}
                          corrected={isCorrected}
                          name="insurance.secondary.groupNumber"
                        />
                      }
                      onHighlightField={handleOnHighlightField}
                    >
                      <Input control={form.control} name="insurance.secondary.groupNumber" />
                    </InputContainer>
                    <InputContainer
                      hidden={requirementFieldDictionary.secondary_policy_number === undefined}
                      highlightFieldId="secondary_policy_number"
                      icon={
                        <ConfidenceIcon
                          confidence={orderSeed.extractionResultsSet?.secondaryInsurance.policyNumberConfidence}
                          control={form.control}
                          corrected={isCorrected}
                          name="insurance.secondary.policyNumber"
                        />
                      }
                      onHighlightField={handleOnHighlightField}
                    >
                      <Input control={form.control} name="insurance.secondary.policyNumber" />
                    </InputContainer>
                  </InputSubSection>
                </Stack>
              </InputSection>

              <InputSection label="Provider">
                <InputContainer
                  hidden={requirementFieldDictionary.provider_npi === undefined}
                  highlightFieldId="provider_npi"
                  icon={
                    <ConfidenceIcon
                      confidence={orderSeed.extractionResultsSet?.providerNpiConfidence}
                      control={form.control}
                      corrected={isCorrected}
                      name="referringProvider.npi"
                    />
                  }
                  onHighlightField={handleOnHighlightField}
                >
                  <Input control={form.control} name="referringProvider.npi" />
                </InputContainer>
                <InputContainer
                  hidden={requirementFieldDictionary.provider_first_name === undefined}
                  highlightFieldId="provider_first_name"
                  icon={
                    <ConfidenceIcon
                      confidence={orderSeed.extractionResultsSet?.providerFirstNameConfidence}
                      control={form.control}
                      corrected={isCorrected}
                      name="referringProvider.firstName"
                    />
                  }
                  onHighlightField={handleOnHighlightField}
                >
                  <Input control={form.control} name="referringProvider.firstName" />
                </InputContainer>
                <InputContainer
                  hidden={requirementFieldDictionary.provider_last_name === undefined}
                  highlightFieldId="provider_last_name"
                  icon={
                    <ConfidenceIcon
                      confidence={orderSeed.extractionResultsSet?.providerLastNameConfidence}
                      control={form.control}
                      corrected={isCorrected}
                      name="referringProvider.lastName"
                    />
                  }
                  onHighlightField={handleOnHighlightField}
                >
                  <Input control={form.control} name="referringProvider.lastName" />
                </InputContainer>
              </InputSection>

              <InputSection label="Order">
                <InputContainer
                  hidden={requirementFieldDictionary.referral_date === undefined}
                  highlightFieldId="referral_date"
                  icon={
                    <ConfidenceIcon
                      confidence={orderSeed.extractionResultsSet?.referralDateConfidence}
                      control={form.control}
                      corrected={isCorrected}
                      name="order.referralDate"
                    />
                  }
                  label="Referral Date"
                  onHighlightField={handleOnHighlightField}
                >
                  <DateInput control={form.control} name="order.referralDate" />
                </InputContainer>
                <InputContainer
                  hidden={requirementFieldDictionary.subcategory_name === undefined}
                  highlightFieldId="subcategory_name"
                  icon={
                    <ConfidenceIcon
                      confidence={orderSeed.extractionResultsSet?.subcategoryNameConfidence}
                      control={form.control}
                      corrected={isCorrected}
                      name="order.subcategoryName"
                    />
                  }
                  onHighlightField={handleOnHighlightField}
                >
                  <SubcategoryField form={form} />
                </InputContainer>

                <Stack mt={1} spacing={2}>
                  {requirementFieldDictionary.icd_10_code !== undefined && (
                    <DiagnosisInputSection
                      form={form}
                      isCorrected={isCorrected}
                      onHighlightField={handleOnHighlightField}
                      orderSeed={orderSeed}
                    />
                  )}
                </Stack>

                {flags.fax_intake_qualification_fields && (
                  <InputSubSection label="Qualification Fields">
                    <InputContainer
                      hidden={requirementFieldDictionary.chart_notes === undefined}
                      highlightFieldId="chart_notes"
                      icon={
                        <ConfidenceIcon
                          confidence={orderSeed.extractionResultsSet?.chartNotesConfidence}
                          control={form.control}
                          corrected={isCorrected}
                          name="clinicalInfo.chartNotes"
                        />
                      }
                      onHighlightField={undefined}
                    >
                      <Input control={form.control} name="clinicalInfo.chartNotes" />
                    </InputContainer>
                    <InputContainer
                      hidden={requirementFieldDictionary.blood_gas_study === undefined}
                      highlightFieldId="blood_gas_study"
                      icon={
                        <ConfidenceIcon
                          confidence={orderSeed.extractionResultsSet?.bloodGasStudyConfidence}
                          control={form.control}
                          corrected={isCorrected}
                          name="clinicalInfo.bloodGasStudy"
                        />
                      }
                      onHighlightField={undefined}
                    >
                      <Input control={form.control} name="clinicalInfo.bloodGasStudy" />
                    </InputContainer>
                    <InputContainer
                      hidden={requirementFieldDictionary.cover_sheet === undefined}
                      highlightFieldId="cover_sheet"
                      icon={
                        <ConfidenceIcon
                          confidence={orderSeed.extractionResultsSet?.coverSheetConfidence}
                          control={form.control}
                          corrected={isCorrected}
                          name="clinicalInfo.coverSheet"
                        />
                      }
                      onHighlightField={undefined}
                    >
                      <Input control={form.control} name="clinicalInfo.coverSheet" />
                    </InputContainer>
                    <InputContainer
                      hidden={requirementFieldDictionary.patient_ahi === undefined}
                      highlightFieldId="patient_ahi"
                      icon={
                        <ConfidenceIcon
                          confidence={orderSeed.extractionResultsSet?.patientAhiConfidence}
                          control={form.control}
                          corrected={isCorrected}
                          name="clinicalInfo.patientAhi"
                        />
                      }
                      onHighlightField={undefined}
                    >
                      <NumberInput control={form.control} name="clinicalInfo.patientAhi" />
                    </InputContainer>
                    <InputContainer
                      hidden={requirementFieldDictionary.patient_bmi === undefined}
                      highlightFieldId="patient_bmi"
                      icon={
                        <ConfidenceIcon
                          confidence={orderSeed.extractionResultsSet?.patientBmiConfidence}
                          control={form.control}
                          corrected={isCorrected}
                          name="clinicalInfo.patientBmi"
                        />
                      }
                      onHighlightField={undefined}
                    >
                      <NumberInput control={form.control} name="clinicalInfo.patientBmi" />
                    </InputContainer>
                    <InputContainer
                      hidden={requirementFieldDictionary.patient_bp === undefined}
                      highlightFieldId="patient_bp"
                      icon={
                        <ConfidenceIcon
                          confidence={orderSeed.extractionResultsSet?.patientBpConfidence}
                          control={form.control}
                          corrected={isCorrected}
                          name="clinicalInfo.patientBp"
                        />
                      }
                      onHighlightField={undefined}
                    >
                      <Input control={form.control} name="clinicalInfo.patientBp" />
                    </InputContainer>
                    <InputContainer
                      hidden={requirementFieldDictionary.patient_ess === undefined}
                      highlightFieldId="patient_ess"
                      icon={
                        <ConfidenceIcon
                          confidence={orderSeed.extractionResultsSet?.patientEssConfidence}
                          control={form.control}
                          corrected={isCorrected}
                          name="clinicalInfo.patientEss"
                        />
                      }
                      onHighlightField={undefined}
                    >
                      <NumberInput control={form.control} name="clinicalInfo.patientEss" />
                    </InputContainer>

                    <InputContainer
                      hidden={requirementFieldDictionary.patient_hypopnea_score === undefined}
                      highlightFieldId="patient_hypopnea_score"
                      icon={
                        <ConfidenceIcon
                          confidence={orderSeed.extractionResultsSet?.patientHypopneaScoreConfidence}
                          control={form.control}
                          corrected={isCorrected}
                          name="clinicalInfo.patientHypopneaScore"
                        />
                      }
                      onHighlightField={undefined}
                    >
                      <NumberInput control={form.control} name="clinicalInfo.patientHypopneaScore" />
                    </InputContainer>
                    <InputContainer
                      hidden={requirementFieldDictionary.patient_last_seen_date === undefined}
                      highlightFieldId="patient_last_seen_date"
                      icon={
                        <ConfidenceIcon
                          confidence={orderSeed.extractionResultsSet?.patientLastSeenDateConfidence}
                          control={form.control}
                          corrected={isCorrected}
                          name="clinicalInfo.patientLastSeenDate"
                        />
                      }
                      onHighlightField={undefined}
                    >
                      <Input control={form.control} name="clinicalInfo.patientLastSeenDate" />
                    </InputContainer>
                    <InputContainer
                      hidden={requirementFieldDictionary.patient_neck_circumference_in_inches === undefined}
                      highlightFieldId="patient_neck_circumference"
                      icon={
                        <ConfidenceIcon
                          confidence={orderSeed.extractionResultsSet?.patientNeckCircumferenceInInchesConfidence}
                          control={form.control}
                          corrected={isCorrected}
                          name="clinicalInfo.patientNeckCircumferenceInInches"
                        />
                      }
                      onHighlightField={undefined}
                    >
                      <NumberInput control={form.control} name="clinicalInfo.patientNeckCircumferenceInInches" />
                    </InputContainer>
                    <InputContainer
                      hidden={requirementFieldDictionary.patient_neck_size === undefined}
                      highlightFieldId="patient_neck_size"
                      icon={
                        <ConfidenceIcon
                          confidence={orderSeed.extractionResultsSet?.patientNeckSizeConfidence}
                          control={form.control}
                          corrected={isCorrected}
                          name="clinicalInfo.patientNeckSize"
                        />
                      }
                      onHighlightField={undefined}
                    >
                      <Input control={form.control} name="clinicalInfo.patientNeckSize" />
                    </InputContainer>

                    <InputContainer
                      hidden={requirementFieldDictionary.patient_rdi === undefined}
                      highlightFieldId="patient_rdi"
                      icon={
                        <ConfidenceIcon
                          confidence={orderSeed.extractionResultsSet?.patientRdiConfidence}
                          control={form.control}
                          corrected={isCorrected}
                          name="clinicalInfo.patientRdi"
                        />
                      }
                      onHighlightField={undefined}
                    >
                      <NumberInput control={form.control} name="clinicalInfo.patientRdi" />
                    </InputContainer>
                    <InputContainer
                      hidden={requirementFieldDictionary.patient_rei === undefined}
                      highlightFieldId="patient_rei"
                      icon={
                        <ConfidenceIcon
                          confidence={orderSeed.extractionResultsSet?.patientReiConfidence}
                          control={form.control}
                          corrected={isCorrected}
                          name="clinicalInfo.patientRei"
                        />
                      }
                      onHighlightField={undefined}
                    >
                      <NumberInput control={form.control} name="clinicalInfo.patientRei" />
                    </InputContainer>
                    <InputContainer
                      hidden={requirementFieldDictionary.patient_total_sleep_time === undefined}
                      highlightFieldId="patient_total_sleep_time"
                      icon={
                        <ConfidenceIcon
                          confidence={orderSeed.extractionResultsSet?.patientTotalSleepTimeConfidence}
                          control={form.control}
                          corrected={isCorrected}
                          name="clinicalInfo.patientTotalSleepTime"
                        />
                      }
                      onHighlightField={undefined}
                    >
                      <NumberInput control={form.control} name="clinicalInfo.patientTotalSleepTime" />
                    </InputContainer>
                    <InputContainer
                      hidden={requirementFieldDictionary.prescription === undefined}
                      highlightFieldId="prescription"
                      icon={
                        <ConfidenceIcon
                          confidence={orderSeed.extractionResultsSet?.prescriptionConfidence}
                          control={form.control}
                          corrected={isCorrected}
                          name="clinicalInfo.prescription"
                        />
                      }
                      onHighlightField={undefined}
                    >
                      <Input control={form.control} name="clinicalInfo.prescription" />
                    </InputContainer>
                    <InputContainer
                      hidden={requirementFieldDictionary.provider_signature_present === undefined}
                      highlightFieldId="provider_signature_present"
                      icon={
                        <ConfidenceIcon
                          confidence={orderSeed.extractionResultsSet?.providerSignaturePresentConfidence}
                          control={form.control}
                          corrected={isCorrected}
                          name="clinicalInfo.providerSignaturePresent"
                        />
                      }
                      onHighlightField={undefined}
                    >
                      <Input control={form.control} name="clinicalInfo.providerSignaturePresent" />
                    </InputContainer>
                    <InputContainer
                      hidden={requirementFieldDictionary.sleep_study === undefined}
                      highlightFieldId="sleep_study"
                      icon={
                        <ConfidenceIcon
                          confidence={orderSeed.extractionResultsSet?.sleepStudyConfidence}
                          control={form.control}
                          corrected={isCorrected}
                          name="clinicalInfo.patientAhi"
                        />
                      }
                      onHighlightField={undefined}
                    >
                      <Input control={form.control} name="clinicalInfo.sleepStudy" />
                    </InputContainer>
                  </InputSubSection>
                )}
              </InputSection>
            </Stack>
          </Box>
        </Box>
      );
    },
  ),
);

function SubcategoryField({ form }: { form: OrderDocumentExtractionForm }) {
  const selectedSubcategoryName = form.watch('order.subcategoryName');
  const selectedSubcategoryId = form.watch('order.subcategoryId');
  const intakeSubcategories = useIntakeSubcategories();

  const selectedSubcategory = useMemo(
    () => intakeSubcategories.data?.find((subcategory) => subcategory.id === selectedSubcategoryId),
    [intakeSubcategories.data, selectedSubcategoryId],
  );

  if (selectedSubcategory?.name && selectedSubcategoryName !== selectedSubcategory.name) {
    form.setValue('order.subcategoryName', selectedSubcategory.name, {
      shouldDirty: true,
      shouldValidate: true,
      shouldTouch: true,
    });
  }

  return <ProductSubcategoryInput control={form.control} name="order.subcategoryId" />;
}

type FormProgressHeaderProps = {
  faxBackError?: boolean;
  numOfFields: number;
};

const FormProgressHeader = ({ numOfFields, faxBackError }: FormProgressHeaderProps) => {
  return (
    <Stack spacing={2}>
      <Typography fontWeight="600" variant="h5">
        Payor Requirement Qualifications{' '}
        <Typography color="textSecondary" component="span" variant="h5">
          ({numOfFields})
        </Typography>
      </Typography>

      <Stack spacing={1}>
        {faxBackError && (
          <Alert severity="error">
            <AlertTitle color="error" sx={{ margin: 0 }}>
              We have initiated and sent a provider request for more information.
            </AlertTitle>
          </Alert>
        )}
      </Stack>
    </Stack>
  );
};

function DiagnosisInputSection<T extends string>({
  form,
  orderSeed,
  isCorrected,
  onHighlightField,
}: {
  form: OrderDocumentExtractionForm;
  orderSeed: SupplierIntakeFaxOrderSeed;
  isCorrected: boolean;
  onHighlightField: HighlightFieldCb<T>;
}) {
  const [parentRef] = useAutoAnimate();
  const { fields, remove, append } = useFieldArray({
    control: form.control,
    name: 'order.diagnoses',
    keyName: 'keyId',
  });

  return (
    <InputSubSection
      label="Diagnoses"
      rightComponent={
        <Tooltip disableInteractive title="Add Item">
          <Button
            onClick={() =>
              append({
                resultId: null,
                dxCode: null,
              })
            }
            size="small"
          >
            <RiAddLine />
          </Button>
        </Tooltip>
      }
    >
      <Box ref={parentRef} display="flex" flexDirection="column" gap={1}>
        {fields.map((item, index) => {
          return (
            <Stack key={item.keyId} spacing={1}>
              <Stack flexGrow="1" spacing={1}>
                <InputContainer
                  highlightFieldId={`dx_code_${index + 1}` as T}
                  icon={
                    <ConfidenceIcon
                      confidence={orderSeed.extractionResultsSet?.dxCodes?.[index]?.diagnosisCodeConfidence}
                      control={form.control}
                      corrected={isCorrected}
                      name={`order.diagnoses.${index}.dxCode`}
                    />
                  }
                  label={`DX code ${index + 1}`}
                  onHighlightField={onHighlightField}
                >
                  <DiagnosisInput
                    control={form.control}
                    name={`order.diagnoses.${index}.dxCode`}
                    rules={{ required: true }}
                  />
                </InputContainer>
              </Stack>
              <Box alignSelf="flex-end">
                <Tooltip disableInteractive title="Remove">
                  <span>
                    <IconButton
                      disabled={fields.length === 0}
                      onClick={() => {
                        const removedDiagnosisIds = form.getValues('order.removedDiagnosisIds');

                        if (item.resultId) {
                          form.setValue('order.removedDiagnosisIds', [...removedDiagnosisIds, item.resultId]);
                        }

                        remove(index);
                      }}
                    >
                      <RiDeleteBinLine size="18px" />
                    </IconButton>
                  </span>
                </Tooltip>
              </Box>
            </Stack>
          );
        })}
      </Box>
    </InputSubSection>
  );
}
